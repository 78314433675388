import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { HotelCard } from "../cards/HotelCard";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AddressArray } from "../config/AddressData";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  fetchAllHotels,
  fetchGetCityList,
  fetchGetHotelByDetail,
} from "./HomeFetch";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { getAuthToken } from "../config/config";
import { fetchGetUserData } from "../member/profile/ProfileDataFetch";

export const Home = () => {
  const cookie = new Cookies();
  const jwt = getAuthToken();
  const [userData, setUserData] = useState();
  const searchParams = cookie.get("searchParam");
  const [city, setCity] = useState([0]);
  const cityArr = city;
  const [getHotelData, setHotelData] = useState({
    allHotels: null,
    recommendHotels: null,
    hotHotels: null,
    newHotels: null,
  });

  useEffect(() => {
    alert("系統修整中... 預於12/26上架開放操作");
    if (jwt)
      fetchGetUserData(jwt).then((data) => {
        setUserData(data);
      });
  }, [jwt]);
  useEffect(() => {
    if (searchParams) {
      fetchGetHotelByDetail(searchParams).then((hotels) => {
        setHotelData((prevData) => ({
          ...prevData,
          allHotels: hotels?.hotels,
          hotHotels: hotels?.hotHotels,
          newHotels: hotels?.newHotels,
          recommendHotels: hotels?.bestHotels,
        }));
      });
      console.log("searchHotels");
    } else {
      fetchAllHotels().then((hotels) => {
        setHotelData((prevData) => ({
          ...prevData,
          allHotels: hotels?.hotels,
          hotHotels: hotels?.hotHotels,
          newHotels: hotels?.newHotels,
          recommendHotels: hotels?.bestHotels,
        }));
      });
    }

    fetchGetCityList().then((cities) => {
      setCity(cities);
    });
  }, []);

  const [page, setPage] = useState(0);
  const handlePageChange = (_, page) => {
    setPage(page);
  };
  const today = dayjs();
  const tomorrow = dayjs().add(1, "day");

  const formik = useFormik({
    initialValues: {
      location: searchParams?.city == null ? "" : searchParams?.city,
      travelTime: {
        startDate: searchParams?.start || today,
        endDate: searchParams?.end || tomorrow,
      },
      passengerNum: searchParams?.people || "2",
      keyword: searchParams?.keyword || "",
    },
    validate: (values) => {
      const errors = {};
      if (values.passengerNum == "") errors.passengerNum = "人數不得為空";
      return errors;
    },
    onSubmit: (values) => {
      const necessity = {
        city: values.location,
        start: dayjs(values.travelTime.startDate).format("YYYY-MM-DD"),
        end: dayjs(values.travelTime.endDate).format("YYYY-MM-DD"),
        people: values.passengerNum,
        keyword: values.keyword,
      };
      console.log(necessity);
      cookie.set("searchParam", necessity, {
        path: "/",
        secure: true,
        sameSite: true,
        maxAge: 900,
      });

      // sessionStorage.setItem("searchParams", JSON.stringify(necessity));
      fetchGetHotelByDetail(necessity).then((hotels) => {
        setHotelData((prevData) => ({
          ...prevData,
          allHotels: hotels?.hotels,
          hotHotels: hotels?.hotHotels,
          newHotels: hotels?.newHotels,
          recommendHotels: hotels?.bestHotels,
        }));
      });
    },
  });

  const TypeOfSort = ({ hotels, tabValue }) => {
    return (
      <TabPanel className="p-0" value={tabValue}>
        <div className="flex flex-wrap gap-6 mt-3">
          {!Array.isArray(hotels) ? (
            <div>loading...</div>
          ) : hotels.length !== 0 ? (
            hotels?.map((hotel) => (
              <HotelCard
                bossPage={false}
                hotel={hotel}
                key={hotel?.hotelId}
                isBoss={userData?.userId == hotel.bossId}
              />
            ))
          ) : (
            <div>查無符合條件旅店</div>
          )}
        </div>
      </TabPanel>
    );
  };

  return (
    <div className="mt-10 pb-10 pt-16 mx-auto max-w-[1000px]">
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-12 gap-3 sm:gap-4 mx-3 bg-[#edede9aa] shadow-lg p-4 rounded-2xl"
      >
        <div className="col-span-12 sm:col-span-12 flex justify-between">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disablePast
              label="旅程時間"
              value={dayjs(formik.values.travelTime.startDate)}
              onChange={(newValue) =>
                formik.setFieldValue("travelTime.startDate", newValue)
              }
              className="w-[47%]"
            />
            <div className="text-2xl leading-[3rem]">~</div>
            <DatePicker
              disablePast
              minDate={dayjs(formik.values.travelTime.startDate).add(1, "day")}
              maxDate={dayjs(formik.values.travelTime.startDate).add(
                1,
                "month"
              )}
              value={dayjs(formik.values.travelTime.endDate)}
              onChange={(newValue) =>
                formik.setFieldValue("travelTime.endDate", newValue)
              }
              className="w-[47%]"
            />
          </LocalizationProvider>
        </div>

        <div className="col-span-12 sm:col-span-4">
          <FormControl fullWidth>
            <InputLabel id="location">你要去哪裡？</InputLabel>
            <Select
              labelId="location"
              value={formik.values.location}
              label="你要去哪裡？"
              name="location"
              onChange={formik.handleChange}
            >
              <MenuItem value="">=========全台各地都行========</MenuItem>
              {cityArr?.map((city, index) => (
                <MenuItem key={index} value={city}>
                  {AddressArray[`${city}`][0]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-span-12 sm:col-span-3">
          <TextField
            fullWidth
            type="number"
            label="旅遊人數"
            variant="outlined"
            name="passengerNum"
            value={formik.values.passengerNum}
            onChange={(e) => {
              const value = Number(e.target.value);
              if (value > 1 && value <= 50) {
                formik.handleChange(e);
              } else if (value > 50) {
                formik.setFieldValue("passengerNum", 50);
              } else {
                formik.setFieldValue("passengerNum", 1);
              }
            }}
            error={
              formik.values.passengerNum == "" || formik.values.passengerNum < 1
            }
            helperText={
              (formik.values.passengerNum < 1 ||
                formik.values.passengerNum == "") &&
              formik.errors.passengerNum
            }
          />
        </div>
        <div className="col-span-12 sm:col-span-3">
          <TextField
            className="w-full"
            label="關鍵字搜尋"
            variant="outlined"
            value={formik.values.keyword}
            onChange={formik.handleChange}
            name="keyword"
          />
        </div>
        <div className=" col-span-12 sm:col-span-2">
          <Button
            fullWidth
            disabled={!formik.isValid}
            type="submit"
            sx={{
              fontSize: "16px",
              bgcolor: "#415a7799",
              height: "55px",
            }}
            variant="contained"
          >
            送出
          </Button>
        </div>
      </form>

      <div className="mt-5 mx-4">
        <TabContext value={String(page)}>
          <TabList
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "black", // 自訂底線顏色
                height: "3px", // 自訂底線寬度
              },
              mb: "25px",
            }}
            onChange={handlePageChange}
          >
            {["全部旅店", "推薦Top9", "熱門Top9", "最新Top9"].map(
              (value, index) => (
                <Tab
                  key={index}
                  sx={{
                    "&.Mui-selected": {
                      color: "black",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      borderRadius: "15px",
                    },

                    fontSize: "1rem",
                  }}
                  label={value}
                  value={String(index)}
                />
              )
            )}
          </TabList>
          <TypeOfSort hotels={getHotelData.allHotels} tabValue={String(0)} />
          <TypeOfSort
            hotels={getHotelData.recommendHotels}
            tabValue={String(1)}
          />
          <TypeOfSort hotels={getHotelData.hotHotels} tabValue={String(2)} />
          <TypeOfSort hotels={getHotelData.newHotels} tabValue={String(3)} />
          {/* <TabPanel className="p-0" value={String(0)}>
            <div className="flex flex-wrap gap-6 mt-3">
              {getHotelData.allHotels?.length !== 0 ? (
                getHotelData.allHotels?.map((hotel, index) => (
                  <HotelCard
                    userId={userId}
                    hotel={hotel}
                    key={index}
                    onClick={() => navigate(`/hotel/${hotel?.hotelId}`)}
                    isClient={true}
                  />
                ))
              ) : (
                <div>查無符合條件旅店</div>
              )}
            </div>
          </TabPanel> */}

          {/* <TabPanel className="p-0" value={String(1)}>
            <div className="flex flex-wrap gap-6 mt-3">
              {getHotelData.recommendHotels?.map((hotel, index) => (
                <HotelCard
                  hotel={hotel}
                  key={index}
                  onClick={() => navigate(`/hotel/${hotel?.hotelId}`)}
                  isClient={true}
                />
              ))}
            </div>
          </TabPanel> */}
          {/* <TabPanel className="p-0" value={String(2)}>
            <div className="flex flex-wrap gap-6 mt-3">
              {getHotelData.hotHotels?.map((hotel, index) => (
                <HotelCard
                  hotel={hotel}
                  key={index}
                  onClick={() => navigate(`/hotel/${hotel?.hotelId}`)}
                  isClient={true}
                />
              ))}
            </div>
          </TabPanel>

          <TabPanel className="p-0" value={String(3)}>
            <div className="flex flex-wrap gap-6 mt-3">
              {getHotelData.newHotels?.map((hotel, index) => (
                <HotelCard
                  hotel={hotel}
                  key={index}
                  onClick={() => navigate(`/hotel/${hotel?.hotelId}`)}
                  isClient={true}
                />
              ))}
            </div>
          </TabPanel> */}
        </TabContext>
      </div>
    </div>
  );
};
